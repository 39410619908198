<template>
  <base-section
    id="kitchencleaner"
    space="36"
  >
    <v-spacer />
    <div v-if="$route.name=='주방용 세척제'">
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>
      <base-section-heading title="야채과일용/식기용" />
      <v-container
      class="text-center"
      >
        야채과일용 세제는 고급 중성 세제로써 세척력이 우수할 뿐만 아니라, <br>
        높은 수중 용해력으로 야채과일의 세척 후 유해물질이 남지 않아 야채과일 세척 시 안심하시고 사용할 수 있습니다. <br>
        주방기구 및 조리기구용 세제 또한 완전 중성 세제로써 세척력 및 유화력이 뛰어나 기믈 때 세척에 탁월한 효과가 있습니다.
        <br>
      </v-container>
      <v-container>
      </v-container>
      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                야채과일용(1종세제)
              </v-subheader>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                주방기구 및 조리기구 동시 사용 가능 세제(1종세제)
              </v-subheader>
            </v-card>
        </v-col>
      <div>
        <v-tabs  class="hidden-sm-and-down"></v-tabs>
      </div>
      <v-divider></v-divider>
      <v-spacer />
      <div>
        <v-tabs  class="hidden-sm-and-down"></v-tabs>
      </div>
      <base-section-heading title="오븐세척제" />
      <v-container
      class="text-center"
      >
        강력한 기름 제거력을 가진 알카리 세제로써 오븐이나 그릴 뿐만 아니라 후드 및 가스렌지 주변, <br>
        기타 조리기구 등 굳은 기름때 및 탄화카본 제거에 강력한 효과가 있으며, <br>
        스텐인레스에 사용할 경우 광택효과가 있습니다.
        <br>
      </v-container>
      <v-container>
      </v-container>
      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                스테인리스용 탄화카본 제거제
              </v-subheader>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                비철금속 및 코팅제품용 탄화카본 제거제
              </v-subheader>
            </v-card>
        </v-col>
      <div>
        <v-tabs  class="hidden-sm-and-down"></v-tabs>
      </div>
      <v-divider></v-divider>
      <v-spacer />
      <div>
        <v-tabs  class="hidden-sm-and-down"></v-tabs>
      </div>
      <base-section-heading title="주방용 다목적 세척제" />
      <v-container
      class="text-center"
      >
      다목적 기름때 세척제로써 주방의 바닥, 벽, 주방기구 등의 기름때 및 찌든 때 세척에 탁월한 효과가 있습니다.<br>
      무공해 무독성 세제로 사용 상 안전할 뿐만 아니라, 부식성이 없어 어떤 재질에도 안심하고 사용하실 수 있습니다.<br>

        <br>
      </v-container>
      <v-container>
      </v-container>
      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                주방용 다목적 세제
              </v-subheader>
            </v-card>
          </v-col>
      <div>
        <v-tabs  class="hidden-sm-and-down"></v-tabs>
      </div>
      <v-divider></v-divider>
      <v-spacer />
      <div>
        <v-tabs  class="hidden-sm-and-down"></v-tabs>
      </div>
      <base-section-heading title="담금 세척제" />
      <v-container
      class="text-center"
      >
        은기물의 변색 및 도자기류의 귿은 때 및 스테인을 제거하기 위하여, <br>
        스폰지나 연마제로 문질러 세척할 경우 부드러운 도금 및 표면에 손상을 입히기 되고,<br>
        그 긁힌 자국은 원상 회복이 되지 않습니다.<br>
        <br>
        이런 경우 담금 세척제를 사용하시면 표면의 손상없이 세척이 가능하여 고가의 제품을 오랫동안 사용하실 수 있습니다.
        <br>
      </v-container>
      <v-container>
      </v-container>
      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                은기물 변색 제거제
              </v-subheader>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                도자기류 굳은 때 및 스테인 제거제
              </v-subheader>
            </v-card>
          </v-col>
  </base-section>
</template>

<script>
  export default {
    name: 'Sectionkitchencleaner',
    data: () => ({
      inset: false,
      drawer: null,
      items: [
        '자동식기 세척용',
        '주방용 세척제',
        '위생용 세척제',
        '세탁 및 카페트용',
      ],
    }),

    provide: {
      theme: { isDark: false },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}
</style>
